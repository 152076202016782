.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.question {
	color: #007bff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.input {
	display: flex;
	justify-content: center;
}

.help {
	display: flex;
	flex-direction: column;
	align-items: center;
	color: gray;
	font-style: italic;
	span {
		color: black;
		font-style: normal;
	}
	// transform: scale(1, 1);
	opacity: 1;
	transition: opacity 1s;
	&[custom-hidden=true] {
		// transform: scale(1, 0);
		opacity: 0;
		// height: 0;
	}
}

// @keyframes fade_in_show {
// 	0% {
// 		opacity: 1;
// 		transform: scale(1, 1);
// 	}
// 	100% {
// 		opacity: 0;
// 		transform: scale(1, 0);
// 	}
// }
