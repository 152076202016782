$size: 30px;

.container {
	margin: 3px;
	border: 1px solid transparent;
	box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.5);
	color: #222;
	border-radius: 5px;
	width: $size;
	height: $size;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	&:hover {
		transition: 0.1s ease-in-out;
		transform: scale(1.1);
		color: black;
	}
	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
		color: black;
	}
}
