.container {
	bottom: 0;
	background-color: #DEDEDE;
	color: black;
	div {
		padding: 20px;
	}
	text-align: center;
	margin-top: 40px;
	width: 100%;
}
