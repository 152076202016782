@import "../index.scss";

.container {
	background-color: #EAEAEB;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	font-size: 1rem;
	text-align: center;
	margin-left: var(--navbar-width);
}

.navbar {
	position: fixed;
	background-color: var(--bg-primary);
	overflow: auto;
	@include scrollbar;
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100%;
		li {
			width: 100%;
			&:last-child {
				margin-top: auto;
			}
			a {
				display: flex;
				align-items: center;
				height: var(--navbar-width);
				color: var(--text-primary);
				text-decoration: none;
				filter: grayscale(100%) opacity(0.7);
				transition: var(--transition-speed);
				&[custom-selected=true] {
					filter: grayscale(50%) opacity(1);
				}
				span {
					display: none;
					margin-left: 1rem;
				}
				svg {
					min-width: 2rem;
					width: 3rem;
					height: 3rem;
					margin: 0 1.5rem;
					color: var(--icon);
				}
				&:hover {
					filter: grayscale(0%) opacity(1);
					background: var(--bg-secondary);
					color: var(--text-secondary);
				}
			}
		}
		&:hover .logo svg {
			transform: rotate(-180deg);
		}
	}
	transition: width 200ms ease;
}

.logo {
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 1rem;
	text-align: center;
	color: var(--text-secondary);
	background: var(--bg-secondary);
	font-size: 1.5rem;
	letter-spacing: 0.3ch;
	width: 100%;
	svg {
		transform: rotate(0deg);
		transition: transform var(--transition-speed);
	}
}

.content {
	background-color: white;
	margin: 15px;
	padding: 20px;
	border-radius: 5px;
	text-align: left;
	width: 100%;
	min-height: 500px;
	overflow-x: auto;
	@include scrollbar;
}

$phone-width-limit: 600px;

// Large screens
@media only screen and (min-width: $phone-width-limit) {
	.navbar {
		top: 0;
		width: var(--navbar-width);
		height: 100vh;
		&:hover {
			width: 16rem;
			span {
				display: inline;
				transition: opacity var(--transition-speed);
			}
		}
		overflow-x: hidden;
	}
}

// Small screens
@media only screen and (max-width: $phone-width-limit) {
	.navbar {
		bottom: 0;
		width: 100vw;
		height: var(--navbar-width);
		ul {
			flex-direction: row;
			a {
				justify-content: center;
			}
		}
		overflow-y: hidden;
	}
	.logo {
		display: none;
	}
	.container {
		margin: 0;
		margin-bottom: var(--navbar-width);
	}
}
