:root {
	font-size: 16px;
	font-family: "Segoe UI", "Helvetica Neue", "Lucida Grande", Roboto;
	--text-primary: #B6B6B6;
	--text-secondary: #ECECEC;
	--bg-primary: #23232E;
	--bg-secondary: #141418;
	--icon: #FF7EEE;
	--navbar-width: 5rem;
	--scrollbar-width: 0.25rem;
	--transition-speed: 400ms;
}

@mixin scrollbar {
	&::-webkit-scrollbar {
		width: var(--scrollbar-width);
		height: var(--scrollbar-width);
	}
	&::-webkit-scrollbar-track {
		background: #1E1E24;
	}
	&::-webkit-scrollbar-thumb {
		background: #6649B8;
	}
}

body {
	color: black;
	background-color: white;
	margin: 0;
	padding: 0;
	@include scrollbar;
}
