@mixin dims {
	background: #EAEAEB;
	width: 100%;
	margin: 5px;
	border-radius: 5px;
	padding: 25px;
	box-sizing: border-box;
}

.container {
	padding: 5px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	> div {
		@include dims;
	}
}

.author {
	font-weight: bold;
}

.date {
	font-style: italic;
	margin-left: 5px;
	color: gray;
}

.edited {
	margin-left: 5px;
	color: black;
}

.content {
	word-break: break-word;
}

.game {
	display: flex;
	align-items: center;
}
