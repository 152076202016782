$color: #CCC;
$width: 4px;

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	div {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 64px;
			height: 64px;
			margin: $width;
			border: $width solid $color;
			border-radius: 50%;
			animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: $color transparent transparent transparent;
			&:nth-child(1) {
				animation-delay: -0.45s;
			}
			&:nth-child(2) {
				animation-delay: -0.3s;
			}
			&:nth-child(3) {
				animation-delay: -0.15s;
			}
		}
	}
}

@keyframes loading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
