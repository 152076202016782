.container {
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
	align-items: center;
}

.changes {
	& > div {
		h4 {
			$margin: 10px;
			margin-top: $margin;
			margin-bottom: $margin;
			text-align: center;
		}
		border: 1px solid transparent;
		$padding: 40px;
		padding-left: $padding;
		padding-right: $padding;
		margin: 5px;
		border-radius: 5px;
		background: #EAEAEB;
		& > div {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
	}
}
