.container {
	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.date {
	font-style: italic;
	color: gray;
}
