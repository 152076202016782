.container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
