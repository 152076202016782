@mixin dims {
	background: #EAEAEB;
	width: 100%;
	margin: 5px;
	border-radius: 5px;
	padding: 25px;
	box-sizing: border-box;
}

.container {
	padding: 5px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	> div {
		@include dims;
	}
}

.author {
	font-weight: bold;
}

.date {
	font-style: italic;
	margin-left: 5px;
	color: gray;
}

.edited {
	margin-left: 5px;
	color: black;
}

.textarea {
	@include dims;
	font-size: 1rem;
	resize: none;
	&:focus {
		outline: none;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
	}
}

.options {
	display: flex;
}

.content {
	word-break: break-word;
}
